<template>
  <div class="homepage-container">
    <WelcomeMat />
    <ContentfulDynamicPage :page-type-name="PageTypeName.HomePage" />
  </div>
</template>

<script lang="ts" setup>
import { PageMetaKey } from '~/types/pages/page-meta-key.types'
import { TrackingPageType, PageTypeName } from '~/types/pages/page-type.types'

const { setTrackingPageType } = usePageMetaStore()

definePageMeta({
  key: PageMetaKey.CmsPages,
})

onMounted(() => {
  setTrackingPageType(TrackingPageType.CmsPage)
})
</script>
